
import React, { useState } from 'react';
import styled from 'styled-components';
import TopBar from './TopBar';
import Footer from './Footer';

const StyledGrid = styled.div`
  font-family: 'Noto Serif', serif;
  background: #e6e6e6;
  /* padding: 50px 0; */
  padding: 80px 10px 20px 10px
`;

const StyledZingGrid = styled.div`
  /* max-width: 1000px; */
  margin: 0 auto;
  font-family: 'Noto Serif', serif;
`;

const StyledCaption = styled.div`
  background: none;
  color: #414141;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const StyledRow = styled.div`
  background: #ffffff;

  &[layout='card'] {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  }
`;

const StyledCell = styled.div`
  font-size: ${props => (props.index === 1 ? '17px' : '12px')};
  line-height: 1.3;
  text-align: ${props => (props.index === 1 ? 'center' : 'left')};

  &[layout='card'] {
    --zg-card-columns: 33.3%;
  }

  &[layout='card'] {
    --zg-card-columns: 33.3%;
  }

  &[data-field-index='author'] {
    align-items: center;
    justify-content: center;
    font-family: 'Muli', sans-serif;
  }
`;

const StyledAccordionContainer = styled.div`
  flex-direction: ${props => (props.viewport === 'mobile' ? 'column' : 'row')};
  flex-grow: 1;
`;

const StyledAccordionInput = styled.input`
  display: none;
`;

const StyledAccordionLabel = styled.label`
  position: relative;
  display: block;
  background: #f7f7f7;
  border-radius: 0.25em;
  cursor: pointer;
  margin-bottom: 0.125em;
  padding: 0.75rem 1em;
  z-index: 20;
  border: 1px solid #cccccc;
  text-align: center;
  font-size: 13px;

  &:hover {
    background: #ccc;
  }

  &::after {
    content: '+';
    position: absolute;
    right: 5px;
  }

  ${StyledAccordionInput}:checked + &::after {
    content: '-';
    position: absolute;
    right: 5px;
  }
`;

const StyledAccordionArticle = styled.article`
  background: #f9f9f9;
  max-height: ${props => (props.isOpen ? '500px' : '0')};
  overflow: hidden;
  z-index: 10;
  opacity: ${props => (props.isOpen ? '1' : '0')};
  transition: all 0.7s ease;
  border: 1px solid #cccccc;
  color: #383838;
  font-size: 13px;
  line-height: 1.5;
`;

const StyledFooter = styled.div`
  font-family: 'Muli', sans-serif;
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: #414141;
`;

const StyledArticlesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ReactStyledComponent = () => {
  // const [accordionStates, setAccordionStates] = useState(Array(3).fill(false));
  const [openIndex, setOpenIndex] = useState(null);
  // const handleToggleAccordion = index => {
  //   const newAccordionStates = [...accordionStates];
  //   newAccordionStates[index] = !newAccordionStates[index];
  //   setAccordionStates(newAccordionStates);
  // };
  const articles = [
    {
      id:"1",
      title: "Article 1",
      quote: "Quote for Article 1",
      content:
        "Content for Article 1. This is the detailed information about Article 1.",
    },
    {
      id:"2",

      title: "Article 2",
      quote: "Quote for Article 2",
      content:
        "Content for Article 2. This is the detailed information about Article 2.",
    },
    {
      id:"3",

      title: "Article 3",
      quote: "Quote for Article 3",
      content:
        "Content for Article 3. This is the detailed information about Article 3.",
    },
  ];
  
  // ... (rest of your code using the `articles` array)
  
  const handleToggleAccordion = index => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <>
      <TopBar/>

    <StyledGrid>

    <StyledZingGrid>
      <StyledCaption>Air Quotes</StyledCaption>
      <StyledArticlesContainer>
        {articles.map((article, index) => (
          <StyledRow layout="card" key={index}>
            <StyledCell index={article.id} layout="card">
              {article.title}
            </StyledCell>
            <StyledCell index={article.id} layout="card">
              {article.quote}
              {article.content}
            </StyledCell>
            <StyledCell layout="card" renderer="_bindAccordion">
              <section className="accordion">
                <StyledAccordionInput
                  type="checkbox"
                  id={`changeme${index}`}
                  checked={openIndex === index}
                  onChange={() => handleToggleAccordion(index)}
                />
                <StyledAccordionLabel htmlFor={`changeme${index}`}>
                  Read More
                </StyledAccordionLabel>
                <StyledAccordionArticle isOpen={openIndex === index}>
                  <p>{article.content}</p>
                </StyledAccordionArticle>
              </section>
            </StyledCell>
          </StyledRow>

        ))}
      </StyledArticlesContainer>
      
      <StyledFooter>
        Biography sources: <StyledLink href="https://www.wikipedia.org/" target="_blank" rel="noopener noreferrer">HINAFI YASSIR</StyledLink>
      </StyledFooter>
    </StyledZingGrid>
    <Footer/>
  </StyledGrid>
  </>

    // <StyledGrid>
    //   <StyledZingGrid>
    //     <StyledCaption>Air Quotes</StyledCaption>
    //     <StyledArticlesContainer>
    //       <StyledRow layout="card">
    //         <StyledCell index={1} layout="card">
    //           Article 1 Title
    //         </StyledCell>
    //         <StyledCell index={2} layout="card">
    //           &#8220;Quote from Article 1&#8221;
    //           Content of Article 1...
    //         </StyledCell>
    //         <StyledCell layout="card" renderer="_bindAccordion">
    //           <section className="accordion">
    //             <StyledAccordionInput
    //               type="checkbox"
    //               id="changeme0"
    //               checked={accordionStates[0]}
    //               onChange={() => handleToggleAccordion(0)}
    //             />
    //             <StyledAccordionLabel htmlFor="changeme0">Read More</StyledAccordionLabel>
    //             <StyledAccordionArticle isOpen={accordionStates[0]}>
    //               <p>Content of the first accordion section for Article 1</p>
    //             </StyledAccordionArticle>
    //           </section>
    //         </StyledCell>
    //       </StyledRow>
    //       <StyledRow layout="card">
    //         <StyledCell index={1} layout="card">
    //           Article 2 Title
    //         </StyledCell>
    //         <StyledCell index={2} layout="card">
    //           &#8220;Quote from Article 2&#8221;
    //           Content of Article 2...
    //         </StyledCell>
    //         <StyledCell layout="card" renderer="_bindAccordion">
    //           <section className="accordion">
    //             <StyledAccordionInput
    //               type="checkbox"
    //               id="changeme1"
    //               checked={accordionStates[1]}
    //               onChange={() => handleToggleAccordion(1)}
    //             />
    //             <StyledAccordionLabel htmlFor="changeme1">Read More</StyledAccordionLabel>
    //             <StyledAccordionArticle isOpen={accordionStates[1]}>
    //               <p>Content of the first accordion section for Article 2</p>
    //             </StyledAccordionArticle>
    //           </section>
    //         </StyledCell>
    //       </StyledRow>
    //       <StyledRow layout="card">
    //         <StyledCell index={1} layout="card">
    //           Article 3 Title
    //         </StyledCell>
    //         <StyledCell index={2} layout="card">
    //           &#8220;Quote from Article 3&#8221;
    //           Content of Article 3...
    //         </StyledCell>
    //         <StyledCell layout="card" renderer="_bindAccordion">
    //           <section className="accordion">
    //             <StyledAccordionInput
    //               type="checkbox"
    //               id="changeme2"
    //               checked={accordionStates[2]}
    //               onChange={() => handleToggleAccordion(2)}
    //             />
    //             <StyledAccordionLabel htmlFor="changeme2">Read More</StyledAccordionLabel>
    //             <StyledAccordionArticle isOpen={accordionStates[2]}>
    //               <p>Content of the first accordion section for Article 3</p>
    //             </StyledAccordionArticle>
    //           </section>
    //         </StyledCell>
    //       </StyledRow>
    //     </StyledArticlesContainer>
    //     <StyledFooter>
    //     Biography sources: <StyledLink href="https://www.wikipedia.org/" target="_blank" rel="noopener noreferrer">Wikipedia</StyledLink>
    //     </StyledFooter>
    //   </StyledZingGrid>
    // </StyledGrid>
  );
};

export default ReactStyledComponent;
