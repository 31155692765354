import React from 'react';
import styled from 'styled-components';
import ProgressiveImage from 'react-progressive-image-loading';

const CrescendoProgressiveImage = ({ src, alt }) => {
  return (
    <Container>
      <ImageWrapper>
        <CrescendoFilterOverlay />
        <ProgressiveImage
          preview={src}
          src={src}
          transitionTime={1000}
          render={(src, style) => <Image src={src} style={style} />}
        />
      </ImageWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CrescendoFilterOverlay = styled.div`
  position: absolute;
  top: 0;
  left: -50%;
  width: 150%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  filter: blur(10px); /* Initial blur amount */
  transition: filter 1s ease; /* Adjust the transition duration */
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

export default CrescendoProgressiveImage;
