import React, { useState, useRef } from 'react';

import styled, { keyframes } from 'styled-components';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import img7 from '../assets/img666.png';
import img2 from '../assets/img23.jpg';
import img3 from '../assets/img24.jpg';
import img4 from '../assets/img99.png';
import imgp8 from '../assets/imgp8.PNG';
import imgp9 from '../assets/imgp9.PNG';
import imgp10 from '../assets/imgp10.PNG';
import Banner from '../components/Banner';
// import ImageSlider from '../components/ImageSlider';
import WebGLNeonCursor from '../components/WebGLNeonCursor';

import { useEffect } from 'react';
import News from '../components/News';

  const AppContainer = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: pan-up;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  text-shadow: 0 0 5px #ffffff, 0 0 20px #000, 0 0 30px #000;
`;
const slides = [
    imgp10,

    img7, img2,
    img3,
    imgp9,
    img7, imgp9, img2,
    img3,
    imgp10,
    img4,
    img7, imgp8,


    img4,
    img7, imgp8,


];

const SquareContainer = styled.div`
  /* width: 600px;
  height: 600px; */
  border: 1px solid #ccc;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5px;
  margin: 0 auto;
  margin-top: 25px;
    object-fit: contain;
`;

const SquareImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
    border-radius: 50%;
  &:hover {
   /* transform: scale(1.5) translateY(-10px); */
    /* width: 50%;
  height: 50%; */
  object-fit: cover;
   border-radius: 5%; 
  
  }
`;
const HomeContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: black;
`;

const DockContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  z-index: 999;
  background: black;
`;

const DockIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
   transform: scale(1.5) translateY(-10px);
  
  }
`;

const dockNames = [
    'Finder',
    'Siri',
    'LaunchPad',
    'Contacts',
    'Notes',
    'inder',
    'iri',
    'aunchPad',
    'ontacts',
    'otes',
    'unchPad',
    'ntacts',
    'tes',

    // Add more names here
];
const DockIconName = styled.div`
  position: absolute;

  top: -70px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.9);
  height: 20px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
   /* visibility: hidden;  */
`;
// Assume that the TopBar and Footer components are correctly implemented elsewhere in your project
// const TopBar = () => (
//     <div style={{ height: '60px', background: '#333', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//         This is the TopBar
//     </div>
// );

// const Footer = () => (
//     <div style={{ height: '60px', background: '#333', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//         This is the Footer
//     </div>
// );
const flickerAnimation = keyframes`
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
    opacity: 0.99;
  }
  20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
    opacity: 0.4;
  }
`;


const flickerAnimationSlow = keyframes`
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
    opacity: 0.99;
  }
  20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
    opacity: 0.4;
  }
`;

const flickerAnimationFast = keyframes`
  0%, 49.999%, 52%, 88.999%, 90%, 90.999%, 94%, 100% {
    opacity: 0.99;
  }
  50%, 51.999%, 89%, 89.999%, 91%, 93.999% {
    opacity: 0.4;
  }
`;

const NeonText = styled.h1`
  /* font-family: 'Monoton', cursive; */
   /* font-family: "BPneon", "Comfortaa", cursive; */
  letter-spacing: 3px;
  font-size: 130px;
 color:#000000; 
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  letter-spacing: 8px;
  text-shadow: 1px 0px 4px #0089ffde, 2px 0px 4px #0089ffde, 3px 0px 4px #0089ffde,
    2px 0px 3px #ffe000, 2px 3px 15px #ffe000, 2px 0px 15px, 5px 0px 125px,
    20px 0vw 200px #ffe000, 40px 0vw 200px #ffe000; 
    font-size: 40px;
    padding-top: auto;
  text-align: center;
  background: black;
  /* color: rgba(255, 232, 50, 0.9); */
  /* text-shadow: 0 0 5px rgba(255, 245, 0, 1), 0 0 28px rgba(255, 44, 0, 0.9),
    0 8px 38px black, 0 8px 10px black, 0 2px 12px black,
    0 0 50px rgba(255, 120, 0, 0.5), 0 0 24px rgba(255, 255, 255, 0.8);
  -webkit-text-stroke: 1px rgba(255, 160, 0, 0.45); */
   /* display: none; */
  /* width: 970px; */
  /* height: 240px; */
  /* position: relative;  */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* border-radius: 20px; */
  box-shadow: 0 0 2px 4px rgba(200, 230, 255, 0.5),
    0 -2px 2px 3px rgba(200, 230, 255, 0.5) inset,
    0 0 2px 10px rgb(249 0 0 / 50%),
    0 0 2px 7px rgb(207 210 125 / 70%) inset,
    0 0 12px 13px rgba(0, 50, 255, 0.9),
    0 0 12px 11px rgba(0, 50, 255, 0.7) inset,
    0 8px 30px 18px rgba(0, 0, 0, 0.8), 0 8px 25px 12px rgba(0, 0, 0, 0.7) inset;
`;

const FlickerSlow = styled.span`
  animation: ${flickerAnimationSlow} 4s linear infinite;
`;

const FlickerFast = styled.span`

  animation: ${flickerAnimationFast} 8s linear infinite;
`;

const Home = () => {
    const [lightboxIndex, setLightboxIndex] = useState(null);
    const [lightbox, setLightbox] = useState(false);
    // const icons = [
    //     img7, img2,
    //     img3,
    //     img4,
    //     imgp8,
    //     imgp9,
    //     imgp10,
    // ];
    const dockIcons = [
        "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853ff3bafbac60495771_siri.png",
        "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853743597518c528b9b3_contacts.png",
        img7, img2,
        img3,
        img4,
        imgp8,
        imgp9,
        imgp10,
        "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f708537f18e2cb27247c904_facetime.png",
        "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853a55558a68e192ee08_messages.png",
        "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853943597517f128b9b4_launchpad.png",
        "https://findicons.com/files/icons/569/longhorn_objects/128/trash.png"
    ];

    const handleImageClick = (index) => {
        setLightboxIndex(index);
        setLightbox(true);
    };

    const handleCloseLightbox = () => {
        setLightboxIndex(null);
        setLightbox(false);
    };
    /*** */
    // const iconsRef = useRef([]);

    const handleMouseEnter = (index) => {
        const icons = iconsRef.current;

        icons[index].style.transform = 'scale(1.5) translateY(-10px)';

        const previous = index - 1;
        const previous1 = index - 2;
        const next = index + 1;
        const next2 = index + 2;

        if (previous >= 0) icons[previous].style.transform = 'scale(1.2) translateY(-6px)';
        if (previous1 >= 0) icons[previous1].style.transform = 'scale(1.1)';
        if (next < icons.length) icons[next].style.transform = 'scale(1.2) translateY(-6px)';
        if (next2 < icons.length) icons[next2].style.transform = 'scale(1.1)';
        iconsRef.current[index].style.transform = 'scale(1.5) translateY(-10px)';
        setShowIconName(true);
        setIconName(dockNames[index]);
    };

    // const handleMouseLeave = () => {
    //     const icons = iconsRef.current;

    //     icons.forEach((icon) => {
    //         icon.style.transform = 'scale(1) translateY(0)';
    //     });
    // };
    const handleMouseLeave = () => {
        const icons = iconsRef.current;

        icons.forEach((icon) => {
            icon.style.transform = 'scale(1) translateY(0)';
        });
    };
    const iconsRef = useRef(Array(dockIcons.length).fill(null));
    const [showIconName, setShowIconName] = useState(false);
    const [iconName, setIconName] = useState('');

    // const handleMouseEnter = (index) => {
    //     iconsRef.current[index].style.transform = 'scale(1.5) translateY(-10px)';
    //     setShowIconName(true);
    //     setIconName(dockNames[index]);
    // };

    // const handleMouseLeave = () => {
    //     iconsRef.current.forEach((icon) => (icon.style.transform = 'scale(1) translateY(0px)'));
    //     setShowIconName(false);
    //     setIconName('');
    // };

const AppContainer = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: pan-up;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  text-shadow: 0 0 5px #ffffff, 0 0 20px #000, 0 0 30px #000;
`;
    return (
        <>
                <TopBar />

            <HomeContainer>
                {/* <Banner/> */}
                <div>
                <NeonText data-text="U">
       <FlickerSlow></FlickerSlow > NE<FlickerFast>W</FlickerFast>S
      </NeonText>
      
      <WebGLNeonCursor /> 

      <AppContainer>

      <WebGLNeonCursor />
      
    </AppContainer>
    </div>
    
                {/* <ImageSlider /> */}
                {/* {icons.map((icon, index) => (
                    <DockIcon key={index} src={icon} alt={`App ${index + 1}`} />
                ))} */}
                {/* <DockIcon
                    src="https://example.com/icon-1.png"
                    alt="Icon 1"
                    ref={(el) => (iconsRef.current[0] = el)}
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={handleMouseLeave}
                />
                <DockIcon
                    src="https://example.com/icon-2.png"
                    alt="Icon 2"
                    ref={(el) => (iconsRef.current[1] = el)}
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={handleMouseLeave}
                /> */}

                
                <div className="dock-icons-container">
                    {/* <DockContainer>
                        {dockIcons.map((iconSrc, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                                <DockIcon
                                    key={index}
                                    src={iconSrc}
                                    alt={`Icon ${index + 1}`}
                                    ref={(el) => (iconsRef.current[index] = el)}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                />
                                {showIconName && iconName === dockNames[index] && <DockIconName>{dockNames[index]}</DockIconName>}
                            </div>
                        ))}

                    </DockContainer> */}
                </div>
            </HomeContainer>

            <Footer /></>

    );
};

export default Home;
