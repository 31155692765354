// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import Product from './components/Product';

// const ProductDetails = () => {
//     const { productId } = useParams();
//     const [product, setProduct] = useState(null);
//     console.log(productId)

//     useEffect(() => {
//         // Fetch the details of the product based on the productId
//         axios.get(`${baseUrl}/api/products/${productId}`)
//             .then((response) => {
//                 setProduct(response.data);
//             })
//             .catch((error) => {
//                 console.error('Error fetching product details:', error);
//             });
//     }, [productId]);

//     // If the product data is still being fetched, show a loading message
//     if (!product) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <Product product={product} />

//     );
// };

// export default ProductDetails;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { FaShoppingCart, FaTrash } from 'react-icons/fa';
import { useCart } from '../CarteContext';
// import Product from '../components/Product';
import TopBar from '../components/TopBar';
import { baseUrl } from './../App';

// Custom safeStringify function to handle circular references
// function safeStringify(obj) {
//     const seen = new WeakSet();
//     return JSON.stringify(obj, (key, value) => {
//         if (typeof value === 'object' && value !== null) {
//             if (seen.has(value)) {
//                 return;
//             }
//             seen.add(value);
//         }
//         return value;
//     });
// }

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  max-width: 300px;
  margin: 0 auto;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
`;

const ProductName = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
`;

const ProductPrice = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

const ProductDescription = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

const ProductCategory = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`;

const ProductBrand = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`;

const ProductRating = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`;

const ProductReviews = styled.div`
  font-size: 12px;
`;

const CartIcon = styled.div`
  font-size: 24px;
  color: #333;
`;

const ProductDetails = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const { cartItems, setCartItems } = useCart();
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(true); // Add loading state
  // Custom safeStringify function to handle circular references
  // function safeStringify(obj) {
  //     const seen = new WeakSet();
  //     return JSON.stringify(obj, (key, value) => {
  //         if (typeof value === 'object' && value !== null) {
  //             if (seen.has(value)) {
  //                 return;
  //             }
  //             seen.add(value);
  //         }
  //         return value;
  //     });
  // }
  useEffect(() => {
    // Fetch the details of the product based on the productId
    axios.get(`${baseUrl}/api/products/${productId}`)
      .then((response) => {
        setProduct(response.data);
      })
      .catch((error) => {
        console.error('Error fetching product details:', error);
      });
  }, [productId]);
  // useEffect(() => {
  //   const fetchProductDetails = async () => {
  //     try {
  //       // Fetch the details of the product based on the productId using async/await
  //       const response = await axios.get(`${baseUrl}/api/products/${productId}`);
  //       // Use the safeStringify function to set the product state without circular references
  //       setProduct(response.data);
  //     } catch (error) {
  //       console.error('Error fetching product details:', error);
  //     }
  //   };

  //   fetchProductDetails();
  // }, [productId]);
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/cart}`);
        setCartItems(response.data);
        setLoading(false); // Set loading to false after fetching cart items
      } catch (error) {
        console.error('Error fetching cart items:', error);
      }
    };

    fetchCartItems();
  }, [setCartItems]);
  if (!product) {
    return <div>Loading...</div>;
  }
  if (!product) {
    return <div>Product not found</div>;
  }

  const addToCart = (product) => {
    axios.post(
      `${baseUrl}/api/cart}`,
      {

        productId: product.id,
        userId: user.id,
        quantity: 1,
        name: product.name,
        price: product.price,
        image: product.image
      }
    )
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          console.log("Product added to cart:", response.data);
          setCartItems((prevCartItems) => [...prevCartItems, response.data]);
          // You might want to update your cart state here if needed
        }
      })
      .catch((error) => {
        console.log("Error adding product to cart:", error);
      });
  };

  const removeFromCart = (itemId) => {
    const itemIdString = String(itemId);
    // Remove the item from the cart on the server
    axios.delete(`${baseUrl}/api/cart/${itemIdString}`).then(() => {
      // Update the cart items state by filtering out the removed item
      setCartItems((prevCartItems) => prevCartItems.filter((item) => item.id !== itemId));
    });
  };
  // const removeFromCart = (itemId) => {
  //     // Convert itemId to a string if it's not already
  //     const itemIdString = String(itemId);

  //     // Remove the item from the cart on the server
  //     axios.delete(`${baseUrl}/api/cart/${itemIdString}`).then((response) => {
  //         setCartItems(response.data);
  //     });
  // };
  console.log(cartItems)
  console.log(product)

  const isInCart = cartItems.some((item) => item.id === product.id);
  console.log(product)
  const cartItemsCount = cartItems.length;
  return (
    <>
      <TopBar cartItemsCount={cartItemsCount} />
      <ProductContainer>

        <ProductImage src={product.image} alt={product.name} />
        <ProductName>{product.name}</ProductName>
        <ProductPrice>{product.price} €</ProductPrice>
        <ProductDescription>{product.description}</ProductDescription>
        <ProductCategory>Catégorie: {product.category}</ProductCategory>
        <ProductBrand>Marque: {product.brand}</ProductBrand>
        {/* {product.reviews.length} */}
        <ProductRating>Basé sur 546 avis</ProductRating>
        <ProductReviews>
          VOIR LES AVIS
        </ProductReviews>
        {isInCart ? (
          <CartIcon onClick={() => removeFromCart(product.id)}>
            <FaTrash />
          </CartIcon>
        ) : (
          <CartIcon onClick={() => addToCart(product)}>
            <FaShoppingCart />
            {cartItemsCount}
          </CartIcon>

        )}
        <CartIcon onClick={() => removeFromCart(product.id)}>
          <FaTrash />
        </CartIcon>
      </ProductContainer>

    </>
  );
};

export default ProductDetails;
