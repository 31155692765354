// // App.js (Main component)

// import React, { useContext } from 'react';

// import { Routes, Route, Navigate } from 'react-router-dom';
// import Home from './pages/Home';


// import { ProductsProvider } from './ProductsContext';
// // <-- Import the ShoppingCartPage component
// import ShoppingCart from './components/ShoppingCart';
// import HomePage from './pages/HomePage';
// import ProductDetails from './pages/ProductDetails';
// import Register from './pages/register/Register';
// import Login from './pages/login/Login';
// import { useState } from 'react';
// import { AuthProvider } from './context/AuthContext';
// const nodeEnv = process.env.NODE_ENV;
// let ioUrl;

// if (nodeEnv === 'production') {
//     ioUrl = process.env.REACT_APP_IO_URL || 'https://ba.up.railway.app';
// } else {
//     ioUrl = process.env.REACT_APP_IO_URL || 'http://localhost:4000';
// }

// export const baseUrl = ioUrl;
// export const AuthContext = React.createContext();

// const initialState = {
//   isAuthenticated: false,
//   isAdmin: false,
//   user: JSON.parse(localStorage.getItem("user")) || null,
//   // admin: JSON.parse(localStorage.getItem("admin")) || null,
//   token: null,
// };
// const reducer = (state, action) => {
//   switch (action.type) {
//     case "LOGIN":
//       // localStorage.setItem("user", JSON.stringify(action.payload.user));
//       // localStorage.setItem("token", JSON.stringify(action.payload.token));

//       return {
//         ...state,
//         isAdmin: true,
//         isAuthenticated: true,
//         user: action.payload.user,
//         token: action.payload.token,
//       };
//     case "X":
//       localStorage.clear();
//       return {
//         ...state,
//         isAuthenticated: false,
//         user: null,
//       };
//     default:
//       return state;
      
//   }
  
// };





// const  App = () => {
//       const [state, dispatch] = React.useReducer(reducer, initialState);
//   React.useEffect(() => {
//     const user = JSON.parse(localStorage.getItem("user") || null);
//     const token = (localStorage.getItem("token") || null);
//     if (user && token) {
//       dispatch({
//         type: "LOGIN",
//         payload: {
//           user,
//           token,
//         },
//       });
//     }
//   }, []);
//   console.log(state.isAuthenticated)
//     return (
//     // <Router basename="/">

//         <AuthProvider>
//         <ProductsProvider>
//             <Routes>
//                 {/* Check if user is authenticated */}
//                 {state.isAuthenticated ? (
//                     <>
//                         <Route path="/" element={<Home />} />
//                         <Route path="/homepage" element={<HomePage />} />
//                         <Route path="/product/:productId" element={<ProductDetails />} />
//                         <Route path="/cart" element={<ShoppingCart />} />
//                     </>
//                 ) : (
//                     <>
//                         {/* If not authenticated, redirect to login */}

//                         <Route path="/" element={<Navigate to="/login" />} />
//                         <Route path="/login" element={<Login/>} /> 
//                         <Route path="/register" element={<Register />} />
//                     </>
//                 )}
//             </Routes>
//         </ProductsProvider>
//         </AuthProvider>
//     );
// };

// export default App;
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import { ProductsProvider } from './ProductsContext';
import ShoppingCart from './components/ShoppingCart';
import HomePage from './pages/HomePage';
import ProductDetails from './pages/ProductDetails';
import Register from './pages/register/Register';
import Login from './pages/login/Login';
import { CartProvider } from './CarteContext';
import NewsMagazine from './components/NewsMagazine';
import ArticleList from './components/ArticleList';
import ArticleForm from './components/ArticleForm';
import News from './components/News';
import Services from './components/Services';
import { AuthProvider, useAuthState } from './context/AuthContext'; 
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import ArticleComponent from './components/ArticleComponent';
import ReactStyledComponent from './components/ReactStyledComponent';
import "./App.css"


library.add(fab);

const nodeEnv = process.env.NODE_ENV;




let ioUrl;

if (nodeEnv === 'production') {
    ioUrl = process.env.REACT_APP_IO_URL || 'https://sds-2a76.onrender.com';
} else {
    ioUrl = process.env.REACT_APP_IO_URL || 'http://localhost:4000';
}

export const baseUrl = ioUrl;

  const App = () => {
    const { isAuthenticated } = useAuthState('');
  
    return (
      <AuthProvider>
        <ProductsProvider>
          <CartProvider>
          {/* <NewsMagazine/> */}
          {/* <ArticleList/> */}
          {/* <h1>News Magazine</h1> */}
      {/* <News /> */}
      {/* <ArticleForm /> */}
       <Routes >
          <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
          <Route path="/homepage" element={isAuthenticated ? <HomePage /> : <Navigate to="/login" />} />
          <Route path="/product/:productId" element={isAuthenticated ? <ProductDetails /> : <Navigate to="/login" />} />
          <Route path="/cart" element={isAuthenticated ? <ShoppingCart /> : <Navigate to="/login" />} />
          <Route path="/services" element={isAuthenticated ? <Services /> : <Navigate to="/login" />} />
          <Route path="/news" element={isAuthenticated ? <News /> : <Navigate to="/login" />} />
          <Route path="/articlecomponent" element={isAuthenticated ? <ArticleComponent /> : <Navigate to="/login" />} />
          <Route path="/ReactStyledComponent" element={isAuthenticated ? <ReactStyledComponent /> : <Navigate to="/login" />} />




          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
        </CartProvider>
        </ProductsProvider>
      </AuthProvider>
    );
  };
  
  export default App;