// import React, { useState } from 'react';
// import styled from 'styled-components';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import 'font-awesome/css/font-awesome.min.css';

// const MainWrapper = styled.main`
//   /* Add your main wrapper styles here */
//   font-family: "Halant", serif;
//   font-weight: 400;
//   font-size: 10px;
//   line-height: 1.571em;
//   text-align: justify;
//   color:  palegoldenrod;
//   /* Add more styles as needed */
//   background-color: azure;
// `;


// const MenuSection = styled.section`
//   /* Add your menu section styles here */
//   height: 100vh;
//   position: fixed;
//   z-index: 2000;
//   overflow: hidden;
// `;

// const Nav = styled.nav`
//   /* Add your nav styles here */
//   ul {
//     list-style: none;
//     padding: 0;
//     margin: 0;
//   }

//   li {
//     /* display: inline-block; */
//     margin-right: 20px;
//   }

//   a {
//     text-decoration: none;
//     font-weight: bold;
//     color: #000000;
//     transition: color 0.3s ease-in-out;

//     &:hover {
//       color: greenyellow;
//     }
//   }
// `;


// const MenuList = styled.ul`
//   /* Add your menu list styles here */
//   list-style: none;
//   margin: 0;
//   padding: 0;
// `;

// const MenuItem = styled.li`
//   /* Add your menu item styles here */
//   width: 150px;
//   border-bottom: 1px solid #808080;
//   text-align: right;
//   height: 50px;
//   background: orangered;
//   transition: width 500ms ease-in-out;

//   /* You can add more specific styles for each state of the menu */
//   &.close li:nth-of-type(2) {
//     width: 0;
//     transition: width 420ms ease-in-out;
//   }
//   /* Repeat the above pattern for other menu items */
// `;

// const MenuLink = styled.a`
//   /* Add your menu link styles here */
//   position: absolute;
//   right: 0;
//   font-size: 2em;
//   font-family: "Josefin Sans", sans-serif;
//   text-transform: uppercase;
//   text-decoration: none;
//   color: #000000;
//   line-height: 2.4em;
//   margin-right: 1em;

//   /* Add hover styles */
//   &:hover {
//     color: #cccccc;
//   }
// `;

// const ArticleCover = styled.section`
//   /* Add your article cover styles here */
//   position: relative;
//   width: 100%;
// `;

// const HeroPhoto = styled.div`
//   /* Add your hero photo styles here */
//   height: 100vh;
//   overflow: hidden;
//   background: url(https://40.media.tumblr.com/0ffc52f37ac317fcd870e7a2c6b06fbf/tumblr_nbpb0sdTpR1rlusy7o1_1280.jpg) no-repeat center center fixed;
//   background-size: cover;
// `;
// const ArticleDetailsWrapper = styled.div`
//   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
//   line-height: 0.6;
//   font-size: 1.2em;
//   color: #666666;
//   padding: 2% 10%;

//   @media (max-width: 800px) {
//     padding: 2% 5%;
//   }
// `;
// const ArticleCategory = styled.div`
//   font-family: "Josefin Sans", sans-serif;
//   font-weight: 200;
//   font-style: normal;
//   font-size: 2.5em;
//   text-transform: lowercase;
//   text-decoration: none;
//   color: #cccccc;
//   display: inline-block;
//   /* margin: 1.45em 0 1.5em; */
// `;

// const ArticleTitle = styled.h1`
//   font-family: "Josefin Sans", sans-serif;
//   font-weight: 100;
//   font-style: italic;
//   /* font-size: 6.2em; */
//   line-height: 0.9em;
//   text-transform: uppercase;
//   letter-spacing: 0.05em;
//   color: royalblue;
//   /* margin: 0px; */
//   margin-bottom: 0.4em;

//   > span:last-child {
//     font-family: "Josefin Sans", sans-serif;
//     font-weight: 700;
//     font-style: normal;
//     /* font-size: 3em; */
//     line-height: 1em;
//     text-transform: uppercase;
//     display: block;
//     letter-spacing: 0.05em;
//     word-spacing: 0.05em;
//   }
// `;

// const ArticleAbstract = styled.div`
//   font-family: "Josefin Sans", sans-serif;
//   font-weight: 300;
//   font-style: normal;
//   /* font-size: 2.8em; */
//   line-height: 1.45em;
//   /* color: #f5f5f5; */
//   display: block;
//   text-transform: uppercase;
//   letter-spacing: 0.05em; 
//   margin-bottom: 1em;
//   padding: 0 10%;
// `;

// // ... Rest of the styled components


// // Add more styled components for other sections and elements as needed

// const ArticleComponent = () => {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
  
//     const handleMenuClick = () => {
//       setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen);
//     };
  
//     return (
//       <MainWrapper>
//         <MenuSection className={isMenuOpen ? 'open' : ''}>
//           <Nav role="navigation">
//             <MenuList>
//               <MenuItem id="btn-menu">
//                 <MenuLink onClick={handleMenuClick}>
//                   <i className="fa fa-bars fa-lg"></i>
//                 </MenuLink>
//               </MenuItem>
//               <MenuItem>
//                 <MenuLink href="#">Home</MenuLink>
//               </MenuItem>
//               <MenuItem>
//                 <MenuLink href="#">Articles</MenuLink>
//               </MenuItem>
//               <MenuItem>
//                 <MenuLink href="#">About</MenuLink>
//               </MenuItem>
//               <MenuItem>
//                 <MenuLink href="#">Contact</MenuLink>
//               </MenuItem>
//             </MenuList>
//           </Nav>
//         </MenuSection>
  
//         {/* First Article */}
//         <ArticleCover>
//           <HeroPhoto />
import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'font-awesome/css/font-awesome.min.css';
import TopBar from './TopBar';
const MainWrapper = styled.main`
  /* Add your main wrapper styles here */
  /* font-family: "Halant", serif; */
  /* font-weight: 400;
  font-size: 10px;
  line-height: 1.571em;
  text-align: justify;
  color: red; */
  /* Add more styles as needed */
  background: repeating-linear-gradient(45deg, #b60000cc, #00000094 100px );

`;

const MenuSection = styled.section`
  /* Add your menu section styles here */
  /* height: 100vh; */
  width: 200px;
    -webkit-transition: visibility 0s,all 0.15s ease-in-out;
    transition: visibility 0s,all 0.15s ease-in-out;
    /* height: calc(100vh - 60px); */
    background: #0C0D14;
    content-visibility: auto;
    z-index: 3;
    position: fixed;
    top: 60px;
    left: 0;
    border-right: 1px solid #28293D;
  overflow: hidden;
  background: repeating-linear-gradient(45deg, #ff009bbf, transparent 100px);
`;

const Nav = styled.nav`
  /* Add your nav styles here */
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    /* display: inline-block; */
    margin-right: 20px;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: #ffffff;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: greenyellow;
    }
  }
`;

const MenuList = styled.ul`
  /* Add your menu list styles here */
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  /* Add your menu item styles here */
  width: 150px;
  border-bottom: 1px solid #808080;
  /* text-align: right; */
  height: 50px;
  background: orangered;
  transition: width 500ms ease-in-out;
  background: linear-gradient(45deg, black, transparent);
  /* You can add more specific styles for each state of the menu */
  &.close li:nth-of-type(2) {
    width: 0;
    transition: width 420ms ease-in-out;
  }
  /* Repeat the above pattern for other menu items */
`;

const MenuLink = styled.a`

  /* Add your menu link styles here */
  /* position: absolute; */
  right: 0;
  /* font-size: 2em; */
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  color: #000000;
  line-height: 2.4em;
  margin-right: 1em;

  /* Add hover styles */
  &:hover {
    color: #cccccc;
  }
`;

const ArticleCover = styled.section`
  /* Add your article cover styles here */
  position: relative;
  width: 100%;
`;

const HeroPhoto = styled.div`
  /* Add your hero photo styles here */
  height: 10vh; 
  overflow: hidden;
  background: url('https://sds-2a76.onrender.com/images/22.PNG') no-repeat center center fixed;
  background-size: cover;
`;

const ArticleDetailsWrapper = styled.div`
    /* font-size: 15px; */
    line-height: 1.571em;
    text-align: justify;
    line-height: 0.6;
    /* font-size: 1.2em; */
    /* color: #666666; */
    padding: 2% 5%;

  @media (max-width: 800px) {
    padding: 2% 3%;
  }
`;

const ArticleCategory = styled.div`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 2.5em;
  /* text-transform: lowercase; */
  text-decoration: none;
  color: #cccccc;
  display: inline-block;
  /* margin: 1.45em 0 1.5em; */
`;

const ArticleTitle = styled.h1`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 100;
  font-style: italic;
  /* font-size: 6.2em; */
  line-height: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #e82fa1;
  /* margin: 0px; */
  margin-bottom: 0.4em;

  > span:last-child {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    /* font-size: 3em; */
    line-height: 1em;
    text-transform: uppercase;
    display: block;
    letter-spacing: 0.05em;
    word-spacing: 0.05em;
  }
`;

const ArticleAbstract = styled.div`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
  /* font-size: 2.8em; */
  line-height: 1.45em;
  color: #f5f5f5; 
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.05em; 
  margin-bottom: 1em;
  padding: 0 10%;
`;
const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownButton = styled.button`
  background: none; 
  border: none;
  cursor: pointer;
`;

const DropdownContent = styled.div`
  /* position: absolute; */
  /* top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px; */
  display: ${({ open }) => (open ? 'block' : 'none')};
`;

const MenuItemLink = styled.a`
  display: block;
  margin: 5px 0;
  color: #000;
  text-decoration: none;

  &:hover {
    color: greenyellow;
  }
`;
const ArticleComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen);
  };
  // const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dropdownwrapper = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownwrapper.current && !dropdownwrapper.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <MainWrapper>
    <TopBar/>

         {/* <DropdownWrapper ref={dropdownwrapper}>
        {/* <DropdownButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <i className="fa fa-bars fa-lg"></i>
        </DropdownButton> */}
        {/* <DropdownContent open={isMenuOpen}>
          <MenuItemLink href="#">Home</MenuItemLink>
          <MenuItemLink href="#">Articles</MenuItemLink>
          <MenuItemLink href="#">About</MenuItemLink>
          <MenuItemLink href="#">Contact</MenuItemLink>
        </DropdownContent>
      </DropdownWrapper> */} 
      <MenuSection className={isMenuOpen ? 'false' : 'false'}>
      <DropdownWrapper ref={dropdownwrapper}>
    
        <Nav role="navigation">
          <MenuList>
            {/* <MenuItem id="btn-menu">
              <MenuLink onClick={handleMenuClick}>
                <i className="fa fa-bars fa-lg">1</i>
              </MenuLink>
            </MenuItem> */}
            <DropdownButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <i className="fa fa-bars fa-lg"></i>
        </DropdownButton>
        <DropdownContent open={isMenuOpen}>
            <MenuItem>
              <MenuLink href="./">Home</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href="./news">news</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href="#">About</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href="#">Contact</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href="./services">Servises</MenuLink>
            </MenuItem>
            </DropdownContent>
          </MenuList>
        </Nav>
        </DropdownWrapper>
      </MenuSection> 

      {/* First Article */}
      <ArticleCover>
        <HeroPhoto />
        <ArticleDetailsWrapper>
          <ArticleCategory>Adventures</ArticleCategory>
          <ArticleTitle>
            Title <span>Subtitle</span>
          </ArticleTitle>
          <ArticleAbstract>
            Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux
          </ArticleAbstract>
          {/* Add other article details here */}
        </ArticleDetailsWrapper>
      </ArticleCover>

      {/* Second Article */}
      <ArticleCover>
        <HeroPhoto />
          <ArticleDetailsWrapper>
          {/* <a href="2">Kate</a> */}
            <ArticleCategory>Kate</ArticleCategory>
            <ArticleTitle>
              Title <span>Subtitle</span>
            </ArticleTitle>
            <ArticleAbstract>
              Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux
            </ArticleAbstract>
            {/* Add other article details here */}
          </ArticleDetailsWrapper>
        </ArticleCover>
  
        {/* Second Article */}
        <ArticleCover>
          <HeroPhoto />
          <ArticleDetailsWrapper>
            <ArticleCategory>Breaking</ArticleCategory>
            <ArticleTitle>
              Important News <span>Breaking Now</span>
            </ArticleTitle>
            <ArticleAbstract>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut suscipit lorem. Nulla facilisi. Sed a ex vel purus scelerisque cursus.
            </ArticleAbstract>
            {/* Add other article details here */}
          </ArticleDetailsWrapper>
        </ArticleCover>
  
        {/* Repeat for more articles */}
        {/* Third Article */}
        <ArticleCover>
          <HeroPhoto />
          <ArticleDetailsWrapper>
            <ArticleCategory>Technology</ArticleCategory>
            <ArticleTitle>
              The Future of AI <span>Exploring Possibilities</span>
            </ArticleTitle>
            <ArticleAbstract>
              Fusce vel nunc a massa ultricies dignissim non quis nisi. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.
            </ArticleAbstract>
            {/* Add other article details here */}
          </ArticleDetailsWrapper>
        </ArticleCover>
  
        {/* Fourth Article */}
        <ArticleCover>
          <HeroPhoto />
          <ArticleDetailsWrapper>
            <ArticleCategory>Horizons</ArticleCategory>
            <ArticleTitle>
              Adventures Around the World <span>Discover New Horizons</span>
            </ArticleTitle>
            <ArticleAbstract>
              Etiam pellentesque risus in ante scelerisque, nec auctor augue lacinia. Nulla facilisi. Suspendisse aliquam velit vel laoreet varius.
            </ArticleAbstract>
            {/* Add other article details here */}
          </ArticleDetailsWrapper>
        </ArticleCover>
  
        {/* Fifth Article */}
        <ArticleCover>
          <HeroPhoto />
          <ArticleDetailsWrapper>
            <ArticleCategory><a href="#">Health</a></ArticleCategory>
            <ArticleTitle>
              Wellness Tips <span>Stay Fit and Happy</span>
            </ArticleTitle>
            <ArticleAbstract>
              Maecenas non viverra urna. Aliquam ante felis, mattis eu purus at, scelerisque consectetur turpis. Fusce nec mollis purus. Aenean viverra, odio eget tempus lobortis.
            </ArticleAbstract>
            {/* Add other article details here */}
          </ArticleDetailsWrapper>
        </ArticleCover>
  
        {/* Continue adding articles as needed */}
        
      </MainWrapper>
    );
  };
  
  export default ArticleComponent;