import React from 'react';
import styled, { keyframes } from 'styled-components';
// import imgp9 from '../assets/imgp9.PNG';
// import urb from '../assets/urb.webp';
// import a from '../assets/a.webp';

// import n from '../assets/n.webp';
import DE from '../assets/dee.png';
import V from '../assets/vv.png';

import NET from '../assets/nett.png';
// import fi from '../assets/fi.png';

// Define keyframes for blinking animation
const blinkAnimation = keyframes`
78% {
        opacity: 1;
    }
    79%{
        opacity: 0.2;
    }
    81% {
        opacity: 1;
    }
    82% {
        opacity: 0.2;
    }
    83% {
        opacity: 1;
    }
    92% {
        opacity: 0.2;
    }
    92.5% {
        opacity: 1;
    }
  /* ... rest of the keyframes ... */
`;

// Styled component for the blinking image
const BlinkingImage = styled.div`
  margin-left: 25px;
  padding-left: 0;
  position: relative;
  padding-top: calc(396px / 2228px * 100%);

  img {
    position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;

    &.blinking {
      animation: ${blinkAnimation} linear infinite 2s;
    }
  }
`;
const Portfoliolayout = styled.div `
img {
    max-width: 100%;
     height: auto; 
}
`;

const Banner = () => {
  return (
    <Portfoliolayout className="banner">
      <BlinkingImage >
  


         {/* <img src={urb} alt="Blinking Image" /> 
         <img className="blinking" src={a} alt="Blinking Image" /> 


         <img  src={n} alt="Blinking Image" />  */}
       
        
         {/* 
          <img className="blinking" src={V} alt="Blinking Image" /> <img  src={NET} alt="Blinking Image" />  */}



         {/* <img  src={NET} alt="Blinking Image" />  */}

      </BlinkingImage>
      <div className="banner-text">
        {/* Other content */}
      </div>
    </Portfoliolayout>
  );
};

export default Banner;
// import React from 'react';
// import styled, { keyframes } from 'styled-components';



// // import urb from '../assets/urb.webp';
// // import a from '../assets/a.webp';

// // import n from '../assets/n.webp';


// // Define keyframes for blinking animation
// const blinkAnimation = keyframes`
// 78% {
//         opacity: 1;
//     }
//     79%{
//         opacity: 0.2;
//     }
//     81% {
//         opacity: 1;
//     }
//     82% {
//         opacity: 0.2;
//     }
//     83% {
//         opacity: 1;
//     }
//     92% {
//         opacity: 0.2;
//     }
//     92.5% {
//         opacity: 1;
//     }
//   /* ... rest of the keyframes ... */
// `;

// // Styled component for the blinking image
// const BlinkingImage = styled.div`
//   margin-left: 25px;
//   padding-left: 0;
//   position: relative;
//   padding-top: calc(396px / 2228px * 100%);

//   img {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     height: 50px;

//     &.blinking {
//       animation: ${blinkAnimation} linear infinite 2s;
//     }
//   }
// `;
// const Portfoliolayout = styled.div `
// img {
//     max-width: 100%;
//     height: auto;
// }
// `;

// const Banner = () => {
//   return (
//     <Portfoliolayout className="banner">
//       <BlinkingImage >
  




//       </BlinkingImage>
//       <div className="banner-text">
//         {/* Other content */}
//       </div>
//     </Portfoliolayout>
//   );
// };

// export default Banner;
