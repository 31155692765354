// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import styled from 'styled-components';

// // const HomeContainer = styled.div`
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// // `;

// // const ImageGridContainer = styled.div`
// //   display: grid;
// //   grid-template-columns: repeat(5, 1fr);
// //   grid-gap: 20px;
// // `;

// // const ItemContainer = styled.div`
// //   position: relative;
// //   background-color: #f0f0f0;
// //   padding: 10px;
// //   border-radius: 5px;
// //   cursor: pointer;
// //   transition: transform 0.3s ease-in-out;

// //   &:hover {
// //     transform: scale(1.1);
// //   }
// // `;

// // const ItemImage = styled.img`
// //   width: 100%;
// //   height: 150px;
// //   object-fit: cover;
// //   border-radius: 5px;
// // `;

// // const ItemInfo = styled.div`
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// //   margin-top: 10px;
// // `;

// // const ItemName = styled.div`
// //   font-size: 16px;
// //   font-weight: bold;
// //   margin-bottom: 5px;
// // `;

// // const ItemPrice = styled.div`
// //   font-size: 14px;
// // `;

// // const CartIcon = styled.div`
// //   position: absolute;
// //   top: 10px;
// //   right: 10px;
// //   font-size: 24px;
// //   color: #333;
// // `;

// // const Home = () => {
// //   const items = [
// //     {
// //       name: 'Item 1',
// //       price: '$10',
// //       image: 'https://via.placeholder.com/150x150?text=Item1',
// //     },
// //     {
// //       name: 'Item 2',
// //       price: '$15',
// //       image: 'https://via.placeholder.com/150x150?text=Item2',
// //     },
// //     {
// //       name: 'Item 3',
// //       price: '$20',
// //       image: 'https://via.placeholder.com/150x150?text=Item3',
// //     },
// //     // Add more items here
// //   ];
// //   const [products, setProducts] = useState([]);
// //   const [cartItems, setCartItems] = useState([]);

// //   useEffect(() => {
// //     // Fetch products data from the server
// //     axios.get('${baseUrl}/api/products').then((response) => {
// //       setProducts(response.data);
// //     });

// //     // Fetch cart items from the server
// //     axios.get('${baseUrl}/api/cart').then((response) => {
// //       setCartItems(response.data);
// //     });
// //   }, []);

// //   const addToCart = (product) => {
// //     // Add the selected product to the cart on the server
// //     axios.post('${baseUrl}/api/cart', { product }).then((response) => {
// //       setCartItems(response.data);
// //     });
// //   };

// //   const removeFromCart = (itemId) => {
// //     // Remove the item from the cart on the server
// //     axios.delete(`${baseUrl}/api/cart/${itemId}`).then((response) => {
// //       setCartItems(response.data);
// //     });
// //   };
// //   return (
// //     <HomeContainer>
// //       <h1>Welcome to Our Store</h1>
// //       <div>
// //         <h1>Shopping Cart App</h1>
// //         <div className="product-list">
// //           {products.map((product) => (
// //             <div key={product.id} className="product">
// //               <img src={product.image} alt={product.name} />
// //               <h3>{product.name}</h3>
// //               <p>${product.price}</p>
// //               <button onClick={() => addToCart(product)}>Add to Cart</button>
// //             </div>
// //           ))}
// //         </div>
// //         <div className="cart">
// //           <h2>Cart</h2>
// //           <ul>
// //             {cartItems.map((item) => (
// //               <li key={item.id}>
// //                 <img src={item.product.image} alt={item.product.name} />
// //                 <div>
// //                   <h4>{item.product.name}</h4>
// //                   <p>${item.product.price}</p>
// //                   <button onClick={() => removeFromCart(item.id)}>Remove</button>
// //                 </div>
// //               </li>
// //             ))}
// //           </ul>
// //         </div>
// //       </div>
// //       <ImageGridContainer>
// //         {items.map((item, index) => (
// //           <ItemContainer key={index}>
// //             <ItemImage src={item.image} alt={item.name} />
// //             <CartIcon>&#x1F6D2;</CartIcon>
// //             <ItemInfo>
// //               <ItemName>{item.name}</ItemName>
// //               <ItemPrice>{item.price}</ItemPrice>
// //             </ItemInfo>
// //           </ItemContainer>
// //         ))}
// //       </ImageGridContainer>
// //     </HomeContainer>
// //   );
// // };

// // export default Home;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import TopBar from './components/TopBar';

// const HomeContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
// `;

// const ProductList = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 20px;
//   margin-top: 20px;
// `;

// const ProductCard = styled.div`
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   padding: 10px;
//   cursor: pointer;
//   transition: transform 0.3s ease-in-out;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const ProductImage = styled.img`
//   width: 100%;
//   height: 200px;
//   object-fit: cover;
//   border-radius: 5px;
// `;

// const ProductName = styled.h3`
//   font-size: 16px;
//   font-weight: bold;
//   margin: 10px 0;
// `;

// const ProductPrice = styled.p`
//   font-size: 14px;
//   margin-bottom: 10px;
// `;

// const CartIcon = styled.div`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   font-size: 24px;
//   color: #333;
// `;

// const CartContainer = styled.div`
//   margin-top: 20px;
//   width: 100%;
//   max-width: 600px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   padding: 10px;
// `;

// const CartTitle = styled.h2`
//   font-size: 18px;
//   font-weight: bold;
//   margin-bottom: 10px;
// `;

// const CartItem = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 10px;
// `;

// const CartItemInfo = styled.div`
//   flex-grow: 1;
//   margin-left: 10px;
// `;

// const CartItemImage = styled.img`
//   width: 60px;
//   height: 60px;
//   object-fit: cover;
//   border-radius: 5px;
// `;

// const CartItemName = styled.h3`
//   font-size: 14px;
//   font-weight: bold;
//   margin-bottom: 5px;
// `;

// const CartItemPrice = styled.p`
//   font-size: 12px;
//   margin-bottom: 5px;
// `;

// const RemoveButton = styled.button`
//   font-size: 12px;
//   background-color: #ff5851;
//   color: #fff;
//   border: none;
//   border-radius: 5px;
//   padding: 5px 10px;
//   cursor: pointer;
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: #d4433f;
//   }
// `;

// const Home = () => {
//   const [products, setProducts] = useState([]);
//   const [cartItems, setCartItems] = useState([]);

//   useEffect(() => {
//     // Fetch products data from the server
//     axios.get('${baseUrl}/api/products').then((response) => {
//       setProducts(response.data);
//     });

//     // Fetch cart items from the server
//     axios.get('${baseUrl}/api/cart').then((response) => {
//       setCartItems(response.data);
//     });
//   }, []);

//   const addToCart = (product) => {
//     // Add the selected product to the cart on the server
//     axios.post('${baseUrl}/api/cart', { product }).then((response) => {
//       setCartItems(response.data);
//     });
//   };

//   const removeFromCart = (itemId) => {
//     // Remove the item from the cart on the server
//     axios.delete(`${baseUrl}/api/cart/${itemId}`).then((response) => {
//       setCartItems(response.data);
//     });
//   };

//   return (<>
//     <TopBar />
//     <HomeContainer>

//       <h1>Welcome to Our Store</h1>
//       <ProductList>
//         {products.map((product) => (
//           <ProductCard key={product.id} onClick={() => addToCart(product)}>
//             <ProductImage src={product.image} alt={product.name} />
//             <ProductName>{product.name}</ProductName>
//             <ProductPrice>${product.price}</ProductPrice>
//           </ProductCard>
//         ))}
//       </ProductList>
//       <CartContainer>
//         <CartTitle>Cart</CartTitle>
//         {cartItems.map((item) => (
//           <CartItem key={item.id}>
//             <CartItemImage src={item.product.image} alt={item.product.name} />
//             <CartItemInfo>
//               <CartItemName>{item.product.name}</CartItemName>
//               <CartItemPrice>${item.product.price}</CartItemPrice>
//               <RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>
//             </CartItemInfo>
//           </CartItem>
//         ))}
//       </CartContainer>

//     </HomeContainer>
//   </>
//   );
// };

// export default Home;
import React, { useState, useRef,useEffect } from 'react';

import axios from 'axios';
import styled from 'styled-components';
import TopBar from '../components/TopBar';
import { useCart } from '../CarteContext';
// import ImageSlider from '../components/ImageSlider';
import Footer from '../components/Footer';
// import Product from '../components/Product';
import { ProductsContext } from '../ProductsContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
// import Animation from './Animation';
// import img3 from '../assets/img24.jpg';
// import img4 from '../assets/img99.png';
// import imgp8 from '../assets/imgp8.PNG';
// import imgp9 from '../assets/imgp9.PNG';
// import imgp10 from '../assets/imgp10.PNG';
// import ImageRowAnimation from './ImageRowAnimation';
// import ImageAnimation from './ImageAnimation';
// import SpriteSheetAnimation from './../components/SpriteSheetAnimation';
import { baseUrl } from '../App';
import img7 from '../assets/img666.png';
import img2 from '../assets/img23.jpg';
import img3 from '../assets/img24.jpg';
import img4 from '../assets/img99.png';
import imgp8 from '../assets/imgp8.PNG';
import imgp9 from '../assets/imgp9.PNG';
import imgp10 from '../assets/imgp10.PNG';
// import ImageSlider from '../components/ImageSlider';

const slides = [
    imgp10,

    img7, img2,
    img3,
    imgp9,
    img7, imgp9, img2,
    img3,
    imgp10,
    img4,
    img7, imgp8,


    img4,
    img7, imgp8,


];

// const images = [imgp8, imgp9, imgp10];
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

// const ProductList = styled.div`
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   grid-gap: 20px;
//   margin-top: 20px;
// `;

const ProductCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  height: 110px;

  &:hover {
    transform: scale(1.05);
  }
`;

// const ProductImage = styled.img`
//   width: 100%;
//   height: 200px;
//   object-fit: cover;
//   border-radius: 5px;
// `;

const ProductName = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
`;

const ProductPrice = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

// const CartIcon = styled.div`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   font-size: 24px;
//   color: #333;
// `;

const CartContainer = styled.div`
  margin: 20px 0 150 0;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
`;

const CartTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CartItemInfo = styled.div`
  flex-grow: 1;
  margin-left: 10px;
`;

const CartItemImage = styled.img`
  width: 100%;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
`;

const CartItemName = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CartItemPrice = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`;

const RemoveButton = styled.button`
  font-size: 12px;
  background-color: #ff5851;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d4433f;
  }
`;
const SquareContainer = styled.div`
  /* width: 600px;
  height: 600px; */
  border: 1px solid #ccc;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5px;
  margin: 0 auto;
  margin-top: 25px;
    object-fit: contain;
`;

const SquareImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
    border-radius: 50%;
  &:hover {
   /* transform: scale(1.5) translateY(-10px); */
    /* width: 50%;
  height: 50%; */
  object-fit: cover;
   border-radius: 5%; 
  
  }
`;
const StyledTitle = styled.h1`
  color: gray; /* Text color */
  font-family: 'Lobster', cursive;/* Replace with your desired font */
  font-size: 2rem; /* Font size */
  padding: 20px; /* Padding around the title */
  text-align: center; /* Center the text */
`;
const DockContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  z-index: 999;
`;

const DockIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
   transform: scale(1.5) translateY(-10px);
  
  }
`;

const dockNames = [
    'Finder',
    'Siri',
    'LaunchPad',
    'Contacts',
    'Notes',
    'inder',
    'iri',
    'aunchPad',
    'ontacts',
    'otes',
    'unchPad',
    'ntacts',
    'tes',

    // Add more names here
];
const DockIconName = styled.div`
  position: absolute;

  top: -70px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.9);
  height: 20px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
   /* visibility: hidden;  */
`;

const Home = () => {
  const handleMouseEnter = (index) => {
    const icons = iconsRef.current;
  
    icons[index].style.transform = 'scale(1.5) translateY(-10px)';
  
    const previous = index - 1;
    const previous1 = index - 2;
    const next = index + 1;
    const next2 = index + 2;
  
    if (previous >= 0) icons[previous].style.transform = 'scale(1.2) translateY(-6px)';
    if (previous1 >= 0) icons[previous1].style.transform = 'scale(1.1)';
    if (next < icons.length) icons[next].style.transform = 'scale(1.2) translateY(-6px)';
    if (next2 < icons.length) icons[next2].style.transform = 'scale(1.1)';
    iconsRef.current[index].style.transform = 'scale(1.5) translateY(-10px)';
    setShowIconName(true);
    setIconName(dockNames[index]);
  };
  
  // const handleMouseLeave = () => {
  //     const icons = iconsRef.current;
  
  //     icons.forEach((icon) => {
  //         icon.style.transform = 'scale(1) translateY(0)';
  //     });
  // };
  const handleMouseLeave = () => {
    const icons = iconsRef.current;
  
    icons.forEach((icon) => {
        icon.style.transform = 'scale(1) translateY(0)';
    });
  };
  const dockIcons = [
    "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853ff3bafbac60495771_siri.png",
    "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853743597518c528b9b3_contacts.png",
    img7, img2,
    img3,
    img4,
    imgp8,
    imgp9,
    imgp10,
    "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f708537f18e2cb27247c904_facetime.png",
    "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853a55558a68e192ee08_messages.png",
    "https://uploads-ssl.webflow.com/5f7081c044fb7b3321ac260e/5f70853943597517f128b9b4_launchpad.png",
    "https://findicons.com/files/icons/569/longhorn_objects/128/trash.png"
  ];
  const iconsRef = useRef(Array(dockIcons.length).fill(null));
  const [showIconName, setShowIconName] = useState(false);
  const [iconName, setIconName] = useState('');
  
  const [lightboxIndex, setLightboxIndex] = useState(null);
  const [lightbox, setLightbox] = useState(false);

  // const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));
  const handleImageClick = (index) => {
    setLightboxIndex(index);
    setLightbox(true);
  };
  
  const handleCloseLightbox = () => {
    setLightboxIndex(null);
    setLightbox(false);
};
  // const headers = {
  //   Accept: 'application/json',
  //   Authorization: `Bearer ${token}`,
  // };
  // const [products, setProducts] = useState([]);
  const products = useContext(ProductsContext);

  const { cartItems, setCartItems } = useCart();

  useEffect(() => {

    // Fetch cart items from the server
    axios.get(`${baseUrl}/api/cart`).then((response) => {
      setCartItems(response.data);
      // setCartItemsCount(response.data.length); // Update the cart items count
    });
  }, [setCartItems]);

  // const addToCart = (productId) => {
  //   // Add the selected product to the cart on the server
  //   axios.post('${baseUrl}/api/cart', { productId }).then((response) => {
  //     setCartItems(response.data);
  //   });
  // };
  const addToCart = (product) => {
    axios.post(
      `${baseUrl}/api/cart`,
      {

        productId: product.id,
        userId: user.id,
        quantity: 1,
        name: product.name,
        price: product.price,
        image: product.image
      }
    )
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          console.log("Product added to cart:", response.data);
          setCartItems((prevCartItems) => [...prevCartItems, response.data]);
          // You might want to update your cart state here if needed
        }
      })
      .catch((error) => {
        console.log("Error adding product to cart:", error);
      });
  };

  // const removeFromCart = (itemId) => {
  //   // Remove the item from the cart on the server
  //   axios.delete(`${baseUrl}/api/cart/${itemId}`).then((response) => {
  //     setCartItems(response.data);
  //   });
  // };
  const removeFromCart = (itemId) => {
    // Remove the item from the cart on the server
    axios.delete(`${baseUrl}/api/cart/${itemId}`).then(() => {
      // Update the cart items state by filtering out the removed item
      setCartItems((prevCartItems) => prevCartItems.filter((item) => item.id !== itemId));
    });
  };

  // console.log(cartItemsCount)
  // Calculate the cart items count
  const cartItemsCount = cartItems.length;
  // Wait for the data to be loaded before rendering the child component
  if (products.length === 0) {
    return <div>Loading...</div>;
  }
  const cartArray = Object.values(cartItems)
  return (
    <>
      <TopBar cartItemsCount={cartItemsCount} />
      {/* <ImageSlider /> */}
      <HomeContainer>
        {/* <Animation /> */}
        {/* <ImageAnimation images={images} /> */}
        {/* <SpriteSheetAnimation /> */}
        <StyledTitle>Welcome to Our Store</StyledTitle>
        <SquareContainer>
                    {/* <ImageSlider />
                    <ImageSlider />
                    <ImageSlider />
                    <ImageSlider /> */}
                    {slides.map((src, index) => (
                        <SquareImage
                            key={index}
                            src={src}
                            alt={`Image ${index + 1}`}
                            onClick={() => handleImageClick(index)}
                        />
                    ))}
                </SquareContainer>
                {lightbox && lightboxIndex !== null && (
                    <div onClick={handleCloseLightbox} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.8)', zIndex: 999 }}>
                        <div style={{ maxWidth: '800px', padding: '20px', textAlign: 'center', margin: '100px auto', background: '#fff', borderRadius: '5px' }}>
                            <img src={slides[lightboxIndex]} alt={`Slide ${lightboxIndex + 1}`} style={{ width: '100%', height: 'auto', border: '1px solid #fff', borderRadius: '5px', marginBottom: '10px' }} />
                            <div style={{ fontSize: '16px', color: '#000', marginBottom: '10px' }}>Image Info Here</div>
                            <button onClick={handleCloseLightbox} style={{ padding: '8px 16px', background: '#ccc', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Close</button>
                        </div>
                    </div>
                )}
        <SquareContainer>
          {products.map((product, index) => (

            // Use the Link component to navigate to the product details page
            <div key={index}>
              <Link to={`/product/${product.id}`}>
                <ProductCard >
                  <SquareImage src={baseUrl + '/images/' + product.image} alt={product.name} />
                  <ProductName>{product.name}</ProductName>
                  <ProductPrice>${product.price}</ProductPrice>
                </ProductCard>
                
              </Link>
              <button onClick={() => addToCart(product)}>Add to Cart</button>
            </div>
          ))}


        </SquareContainer>
        {/* 
        {products.map((product) => (
          <Product key={product.id} product={product} />
        ))} */}

        <CartContainer>
          <CartTitle>Cart</CartTitle>
          {cartArray.map((item) => (
            <CartItem key={item.id}>
              <CartItemImage src={baseUrl + '/images/' + item.image} alt={item.name} />
              <CartItemInfo>
                <CartItemName>{item.id}</CartItemName>
                <CartItemPrice>{item.price}</CartItemPrice>
                <RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>
              </CartItemInfo>
            </CartItem>
          ))}
        </CartContainer>
        {/* <ShoppingCart cartItems={cartItems} setCartItems={setCartItems} /> */}
      </HomeContainer >
      <DockContainer>
                        {dockIcons.map((iconSrc, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                                <DockIcon
                                    key={index}
                                    src={iconSrc}
                                    alt={`Icon ${index + 1}`}
                                    ref={(el) => (iconsRef.current[index] = el)}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                />
                                {showIconName && iconName === dockNames[index] && <DockIconName>{dockNames[index]}</DockIconName>}
                            </div>
                        ))}

                    </DockContainer>
      < Footer />
    </>
  );
};

export default Home;
