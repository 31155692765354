import React from 'react';
import styled from 'styled-components';
// import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFiveHundredPx, faAngellist, faAward, faAsymmetrik, faBroadcastTower, faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons'; // For brand icons
// import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight,faAward,faHeadphones} from '@fortawesome/free-solid-svg-icons'; // Import solid icons only
import TopBar from './TopBar';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'



// ... (Other code)

const icons = {
  "500px": "500px",
  "angellist": "angellist",
  "award": "award",
  "asymmetrik": "asymmetrik",
  "broadcast-tower": "broadcast-tower",
  "canadian-maple-leaf": "canadian-maple-leaf",
};

const SectionServices = styled.section`
  padding-top: 110px;
  padding-bottom: 120px;
  font-family: "Poppins", sans-serif;
  background-color: #211f24;
  color: #fff;
`;
const ServiceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const HeaderSection = styled.div`
  margin-bottom: 35px;
`;

const Title = styled.h2`
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 25px;
  text-transform: uppercase;
  font-weight: 700;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
    height: 1px;
    background-color: #f70037;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    width: 45px;
    height: 3px;
    background-color: #f70037;
  }

  span {
    color: #f70037;
  }
`;

const Description = styled.p`
  color: #6f6f71;
`;

const SingleService = styled.div`
  margin-top: 40px;
  background-color: #24252a;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

const Part1 = styled.div`
  padding: 40px 40px 25px;
  border-bottom: 2px solid #1d1e23;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-bottom: 25px;
  font-size: 50px;
  color: #f70037;
`;

const Part2 = styled.div`
  padding: 30px 40px 40px;
`;

const Part2Description = styled.p`
  margin-bottom: 22px;
  color: #6f6f71;
  font-size: 14px;
  line-height: 1.8em;
`;

const ReadMoreLink = styled.a`
  color: #fff;
  font-size: 14px;
  text-decoration: none;

  i {
    margin-right: 10px;
    color: #f70037;
  }
`;

const Services = () => {
  return (
<>
<TopBar/>


  <SectionServices>
      <div className="container">
        {/* First Row */}
        <div className="row justify-content-center text-center">
          <div className="col-md-10 col-lg-8">
            <HeaderSection>
              <Title>HINAFI <span>YASSIR & RAYEN</span>SERVICES </Title>
              <Description>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some injected humour</Description>
            </HeaderSection>
          </div>
        </div>
        <ServiceRow>
          <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>
              <FontAwesomeIcon icon={icon({name: 'user-secret'})} style={{ color: '#e63333' }} /> 
                <Part2Description>Express delivery innovative Design services
             </Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
          <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>
              <FontAwesomeIcon icon={faAward} size="lg" style={{color: "#e63333",}} />
                <Part2Description>Content for the second service.</Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
          <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>
              {/* <FontAwesomeIcon icon={FaAsymmetrik} size="xl" style={{color: "#fa0536",}} /> */}
              <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" style={{ color: '#1DA1F2' }} />

              
                <Part2Description>Content for the third FaAsymmetrik.</Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
          <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>

              <FontAwesomeIcon icon={faHeadphones} style={{color: "#dd2298",}} />
                <Part2Description>Express delivery innovative Design services
             </Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
          <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>
              <FontAwesomeIcon icon={icon({name: 'user-secret'})} /> 
                <Part2Description>Express delivery innovative Design services
             </Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
                    <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>
<FontAwesomeIcon icon={icon({name: 'coffee'})} style={{color: "#e63333",}}/> 
                <Part2Description>Express delivery innovative Design services
             </Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
        </ServiceRow>
  
        <ServiceRow>
          <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>
              <FontAwesomeIcon icon={icon({name: 'user-secret'})} style={{ color: '#e63333' }} /> 
                <Part2Description>Express delivery innovative Design services
             </Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
          <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>
              <FontAwesomeIcon icon={faAward} size="lg" style={{color: "#e63333",}} />
                <Part2Description>Content for the second service.</Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
          <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>
              {/* <FontAwesomeIcon icon={FaAsymmetrik} size="xl" style={{color: "#fa0536",}} /> */}
              <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" style={{ color: '#1DA1F2' }} />

              
                <Part2Description>Content for the third FaAsymmetrik.</Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
          <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>

              <FontAwesomeIcon icon={faHeadphones} style={{color: "#dd2298",}} />
                <Part2Description>Express delivery innovative Design services
             </Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
          <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>
              <FontAwesomeIcon icon={icon({name: 'user-secret'})} /> 
                <Part2Description>Express delivery innovative Design services
             </Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
                    <div className="col-md-4">
            <SingleService>
              {/* Replace with the appropriate content */}
              <Part2>
<FontAwesomeIcon icon={icon({name: 'coffee'})} style={{color: "#e63333",}}/> 
                <Part2Description>Express delivery innovative Design services
             </Part2Description>
                <ReadMoreLink><FontAwesomeIcon icon={faArrowCircleRight} />Read More</ReadMoreLink>
              </Part2>
            </SingleService>
          </div>
        </ServiceRow>
      </div>
    </SectionServices>
</>
    
  );
};

export default Services;
