// // Login.jsx
// import React, { useState } from "react";
// import styles from "./Login.module.css";
// import axios from "axios";
// import { baseUrl } from "./../../App";
// import { useAuthDispatch } from "../../context/AuthContext";
// import { useNavigate } from "react-router-dom";

// const Login = (props) => {
//   const navigate = useNavigate();
//   const dispatch = useAuthDispatch();

//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   // eslint-disable-next-line 
//   const [loading, setLoading] = useState(false);
//   // eslint-disable-next-line 
//   const [error, setError] = useState("");

//   const submitLoginForm = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError("");

//     try {
//       const { data } = await axios.post(
//         `${baseUrl}/api/login`,
//         {
//           username,
//           password,
//         }
//       );

//       if (data) {
//         // localStorage.setItem("token", JSON.stringify(data.token));
        
//         // Dispatch the LOGIN action
//         dispatch({ type: "LOGIN", payload: data });
//         localStorage.setItem("user", JSON.stringify(data.user));


//         // Navigate to the desired page
//         navigate("/");
//         window.location.reload();

//       }
//     } catch (err) {
//       setError(err.response);
//     }

//     setLoading(false);
//   };

//   return (
//     <div className={styles.container}>
//       <h2>ChatBubble</h2>
//       <form onSubmit={submitLoginForm}>
//         <input
//           type="text"
//           placeholder="Username"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//         />
//         <input
//           type="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <button type="submit">Se connecter</button>
//       </form>
//       <div className={styles.logininfo}>
//         <p>Inscrivez-vous pour voir les photos et vidéos de vos amis.</p>
//         <button
//           className=""
//           onClick={() => {
//             navigate("/register");
//           }}
//         >
//           Register
//         </button>
//         <p>Vous avez un compte ? Connectez-vous</p>
//         <p>Téléchargez l'application.</p>
//       </div>
//     </div>
//   );
// };

// export default Login;
import React, { useState } from "react";
import styles from "./Login.module.css";
import axios from "axios";
import { baseUrl } from "./../../App";
import { useAuthDispatch } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import styled from "styled-components"; // Import styled-components

import LoadingSpinner from './../../components/LoadingSpinner';
const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useAuthDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const submitLoginForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { data } = await axios.post(
        `${baseUrl}/api/login`,
        {
          username,
          password,
        }
      );

      if (data) {
        dispatch({ type: "LOGIN", payload: data });
        localStorage.setItem("user", JSON.stringify(data.user));
        navigate("/");
        window.location.reload();
      }
    } catch (err) {
      if (err.response) {
        setError("Invalid username or password");
      } else {
        setError("Oops! We're having trouble connecting to our server right now. Please try again later.");
      }
    }

    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <h2>ChatBubble</h2>
      <form onSubmit={submitLoginForm}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Se connecter</button>
      </form>
      {loading ?(<LoadingSpinner/>):(error && <p className={styles.error}>{error}</p>)}
      <div className={styles.logininfo}>
        <p>Inscrivez-vous pour voir les photos et vidéos de vos amis.</p>
        <button
          className=""
          onClick={() => {
            navigate("/register");
          }}
        >
          Register
        </button>
        <p>Vous avez un compte ? Connectez-vous</p>
        <p>Téléchargez l'application.</p>
      </div>
    </div>
  );
};

export default Login;
