
import React, { useState } from 'react';
// import Item from './Item';
import styled from 'styled-components';
import TopBar from './TopBar';
// import { useCart } from '../CarteContext';
import { useEffect } from 'react';
import axios from 'axios';
import {get,post} from './../api'
import { baseUrl } from './../App';
const ShoppingCartContainer = styled.div`
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ShoppingCartHeading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const CartButton = styled.button`
  background-color: ${({ primary }) => (primary ? '#007bff' : '#28a745')};
  color: #fff;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

// const CartLabel = styled.label`
//   display: block;
//   margin-top: 10px;
// `;

const CartContainer = styled.div`
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

// const CartTitle = styled.h2`
//   text-align: center;
//   margin-bottom: 20px;
// `;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CartItemImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
`;

const CartItemInfo = styled.div`
  flex: 1;
`;

const CartItemName = styled.h3`
  font-size: 16px;
  margin-bottom: 5px;
`;

const CartItemPrice = styled.p`
  font-size: 14px;
  margin-bottom: 5px;
`;

const RemoveButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
`;
/** */
const AddItemSection = styled.div`
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
`;

const PaymentOptionsSection = styled.div`
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
`;

const PaymentOptionLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
`;

const CartButtonWrapper = styled.div`
  margin-top: 20px;
`;
const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

// const ShoppingCart = () => {
//     const [cartItems, setCartItems] = useState([]);
//     console.log('cartItems before render:', cartItems);
//     const [newItem, setNewItem] = useState({});
//     const [paymentOption, setPaymentOption] = useState('');

//     const handleAddItem = () => {
//         // Implement the logic to add the newItem to the cartItems list
//         setCartItems([...cartItems, newItem]);
//         setNewItem({});
//     };

//     const handlePaymentOptionChange = (e) => {
//         setPaymentOption(e.target.value);
//     };
//     const cartItemsCount = cartItems.length;
//     // Calculate the total price of items in the cart
//     const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);
//     // ... (other state and functions)
//     const removeFromCart = (itemId) => {
//         // Remove the item from the cart on the server
//         axios.delete(`${baseUrl}/api/cart/${itemId}`).then((response) => {
//             setCartItems(response.data);
//         });
//     };

//     useEffect(() => {
//         loadCartItemsFromLocalStorage();
//     }, []);

//     const updateCartItems = (newCartItems) => {
//         localStorage.setItem('cartItems', JSON.stringify(newCartItems));
//         setCartItems(newCartItems);

//     };
//     const loadCartItemsFromLocalStorage = () => {
//         const storedCartItems = localStorage.getItem('cartItems');
//         if (storedCartItems) {
//             setCartItems(JSON.parse(storedCartItems));
//         }
//     };
//     // Assuming cartItems is your current state that holds the cart items array
//     updateCartItems(cartItems);
const ShoppingCart = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [cartItems, setCartItems] = useState([]);
    const [newItem, setNewItem] = useState({ name: '', price: '' });
    const [paymentOption, setPaymentOption] = useState('card');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const cartItemsCount = cartItems.length;
  
    useEffect(() => {
      loadCartItemsFromLocalStorage();
    }, []);
  
    const updateLocalStorage = (newCartItems) => {
      localStorage.setItem('cartItems', JSON.stringify(newCartItems));
    };
  
    const loadCartItemsFromLocalStorage = () => {
      const storedCartItems = localStorage.getItem('cartItems');
      if (storedCartItems) {
        setCartItems(JSON.parse(storedCartItems));
      }
    };
  
    useEffect(() => {
      updateLocalStorage(cartItems);
    }, [cartItems]);
  
    const fetchItems = async () => {
      try {
        const response = await get(`${baseUrl}/api/cart`);
        return response.data;
      } catch (error) {
        console.error('Error fetching items:', error);
        return [];
      }
    };
  
    // const processPayment = async () => {
    //     const paymentDetails = {
    //         items: [
    //             { id: 1, name: 'Item 1', price: 10 },
    //             { id: 2, name: 'Item 2', price: 20 }
    //             // Add more items as needed
    //         ],
    //         paymentOption: 'card'
    //     }
    //   try {
    //     const response = await post('${baseUrl}/api/process-payment', paymentDetails);
    //     console.log('Payment processing successful:', response.data);
    //     return response.data;
    //   } catch (error) {
    //     console.error('Error processing payment:', error);
    //     throw new Error('Payment processing failed');
    //   }
    // };
    const processPayment = async () => {
        // Assuming product data is available
        const product = {
          id: 1,
          name: 'Sample Product',
          price: 10,
          image: 'sample-image.jpg'
          // Add more product properties as needed
        };
      
        const paymentDetails = {
          items: [
            { id: product.id, name: product.name, price: product.price }
            // Add more items as needed
          ],
          paymentOption: 'card'
        };
      
        console.log('Starting payment processing...');
      
        try {
          const response = await post(`${baseUrl}/api/paymentIntent`, paymentDetails);
          console.log('Payment processing successful:', response.data);
      
          // Assuming addToCart function logic here
          axios.post(
            `${baseUrl}/api/cart`,
            {
              productId: product.id,
              userId: user.id,
              quantity: 1,
              name: product.name,
              price: product.price,
              image: product.image
            }
          )
            .then((response) => {
              if (response.data.error) {
                console.log(response.data.error);
              } else {
                console.log("Product added to cart:", response.data);
                setCartItems((prevCartItems) => [...prevCartItems, response.data]);
                // You might want to update your cart state here if needed
              }
            })
            .catch((error) => {
              console.log("Error adding product to cart:", error);
            });
      
          return response.data;
        } catch (error) {
          console.error('Error processing payment:', error);
          throw new Error('Payment processing failed');
        }
      };
      
    useEffect(() => {
      const fetchItemsAndInitialize = async () => {
        const items = await fetchItems();
        setCartItems(items);
      };
  
      fetchItemsAndInitialize();
    }, []);
  
    const removeFromCart = (itemId) => {
      const updatedCart = cartItems.filter(item => item.id !== itemId);
      setCartItems(updatedCart);
    };
  
    const handleAddItem = () => {
      if (newItem.name && newItem.price) {
        const newItemWithId = { ...newItem, id: Date.now() };
        setCartItems([...cartItems, newItemWithId]);
        setNewItem({ name: '', price: '' });
      }
    };
  
    const handlePaymentOptionChange = (e) => {
      setPaymentOption(e.target.value);
    };
  
    const handlePayment = async () => {
      if (cartItems.length === 0) {
        setErrorMessage("Cart is empty");
        return;
      }
  
      setLoading(true);
      setErrorMessage('');
  
      const paymentDetails = {
        items: cartItems,
        paymentOption: paymentOption,
      };
  
      try {
        await processPayment(paymentDetails);
        setCartItems([]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage("Payment processing failed. Please try again.");
        console.error("Payment failed:", error);
      }
    };
  
    const calculateTotalPrice = () => {
      return cartItems.reduce((total, item) => total + parseFloat(item.price), 0);
    };
  
    return (
      <ShoppingCartContainer>
        <TopBar cartItemsCount={cartItemsCount} />
        <ShoppingCartHeading>Shopping Cart</ShoppingCartHeading>
  
        <CartContainer>
          {cartItems.length > 0 ? (
            cartItems.map(item => (
              <CartItem key={item.id}>
                {/* Display CartItemImage if item.image exists */}
                {item.image && <CartItemImage src={item.image} alt={item.name} />}
                <CartItemInfo>
                  <CartItemName>{item.name}</CartItemName>
                  <CartItemPrice>{item.price} €</CartItemPrice>
                  <RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>
                </CartItemInfo>
              </CartItem>
            ))
          ) : (
            <div>No items in the cart</div>
          )}
        </CartContainer>
  
        <AddItemSection>
          <h3>Add Item:</h3>
          <Input
            type="text"
            placeholder="Product Name"
            value={newItem.name}
            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
          />
          <Input
            type="number"
            placeholder="Price"
            value={newItem.price}
            onChange={(e) => setNewItem({ ...newItem, price: e.target.value })}
          />
          <CartButtonWrapper>
            <CartButton onClick={handleAddItem}>Add to Cart</CartButton>
          </CartButtonWrapper>
        </AddItemSection>
  
        <PaymentOptionsSection>
          <h3>Payment Options:</h3>
          <PaymentOptionLabel>
            <input
              type="radio"
              value="card"
              checked={paymentOption === 'card'}
              onChange={handlePaymentOptionChange}
            />
            Pay with Card
          </PaymentOptionLabel>
          <PaymentOptionLabel>
            <input
              type="radio"
              value="paypal"
              checked={paymentOption === 'paypal'}
              onChange={handlePaymentOptionChange}
            />
            Pay with PayPal
          </PaymentOptionLabel>
          <CartButtonWrapper>
            <CartButton primary={true} onClick={handlePayment}>
              {loading ? 'Processing...' : 'Proceed to Payment'}
            </CartButton>
          </CartButtonWrapper>
        </PaymentOptionsSection>
  
        {errorMessage && <p className="error-message">{errorMessage}</p>}
  
        <p>Total Price: {calculateTotalPrice()} €</p>
      </ShoppingCartContainer>
    );
  };
  
  export default ShoppingCart;
// const ShoppingCart = () => {
    
//     const [cartItems, setCartItems] = useState([]);
//     const [newItem, setNewItem] = useState({ name: '', price: '' });
//     const [paymentOption, setPaymentOption] = useState('card');
//     const [loading, setLoading] = useState(false);
//     const [errorMessage, setErrorMessage] = useState('');
//     const cartItemsCount = cartItems.length;
//     const paymentDetails = {
//         items: [
//             { id: 1, name: 'Item 1', price: 10 },
//             { id: 2, name: 'Item 2', price: 20 }
//             // Add more items as needed
//         ],
//         paymentOption: 'card'
//     };
    
//     // Call the handlePayment function with the sample paymentDetails
//     // handlePayment(paymentDetails);
    
//     // Call the handlePayment function with the sample paymentDetails
//     // handlePayment(paymentDetails);
//         useEffect(() => {
//             // eslint-disable-next-line 
//             loadCartItemsFromLocalStorage();
//         }, []);
        
//         const updateLocalStorage = (newCartItems) => {
//             localStorage.setItem('cartItems', JSON.stringify(newCartItems));
//         };
    
//         const loadCartItemsFromLocalStorage = () => {
//             const storedCartItems = localStorage.getItem('cartItems');
//             if (storedCartItems) {
//                 setCartItems(JSON.parse(storedCartItems));
//             }
//         };
    
//         useEffect(() => {
//             updateLocalStorage(cartItems);
//         }, [cartItems]);
//     const fetchItems = async () => {
//         try {
//             const response = await get('${baseUrl}/api/cart'); // Replace with your actual items API endpoint
//             return response.data;
//         } catch (error) {
//             console.error('Error fetching items:', error);
//             return [];
//         }
//     };

//     const processPayment = async (paymentDetails) => {
//         try {
//             const response = await post('${baseUrl}/api/process-payment', paymentDetails); // Replace with your actual payment processing API endpoint
//             return response.data;
//         } catch (error) {
//             console.error('Error processing payment:', error);
//             throw new Error('Payment processing failed');
//         }
//     };

//     useEffect(() => {
//         const fetchItemsAndInitialize = async () => {
//             const items = await fetchItems();
//             setCartItems(items);
//         };

//         fetchItemsAndInitialize();
//     }, []);

//     const removeFromCart = (itemId) => {
//         const updatedCart = cartItems.filter(item => item.id !== itemId);
//         setCartItems(updatedCart);
//     };

//     const handleAddItem = () => {
//         console.log("Adding item to cart:", newItem);
//         if (newItem.name && newItem.price) {
//             const newItemWithId = { ...newItem, id: Date.now() };
//             setCartItems([...cartItems, newItemWithId]);
//             setNewItem({ name: '', price: '' });
//         }
//     };

//     const handlePaymentOptionChange = (e) => {
//         setPaymentOption(e.target.value);
//     };

//     const handlePayment = async () => {
//         console.log('handlePayment called');
//         console.log('paymentDetails:', paymentDetails);
//         if (cartItems.length === 0) {
//             setErrorMessage("Cart is empty");
//             return;
//         }

//         setLoading(true);
//         setErrorMessage('');

//         const paymentDetails = {
//             items: cartItems,
//             paymentOption: paymentOption
//         };

//         try {
//             await processPayment(paymentDetails);
//             setCartItems([]);
//             setLoading(false);
//             console.log(paymentDetails);
//         } catch (error) {
//             setLoading(false);
//             setErrorMessage("Payment processing failed. Please try again.");
//             console.error("Payment failed:", error);
//         }
//     };

//     const calculateTotalPrice = () => {
//         return cartItems.reduce((total, item) => total + parseFloat(item.price), 0);
//     };

//     return (
//         <ShoppingCartContainer>
//              <TopBar cartItemsCount={cartItemsCount} />
//             <ShoppingCartHeading>Shopping Cart</ShoppingCartHeading>
            
//             <CartContainer>
//                 {cartItems.length > 0 ? (
//                     cartItems.map(item => (
//                         <CartItem key={item.id}>
//                             <CartItemImage src={item.image} alt={item.name} />
//                             <CartItemInfo>
//                                 <CartItemName>{item.name}</CartItemName>
//                                 <CartItemPrice>{item.price} €</CartItemPrice>
//                                 <RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>
//                             </CartItemInfo>
//                         </CartItem>
//                     ))
//                 ) : (
//                     <div>No items in the cart</div>
//                 )}
//             </CartContainer>
            
//             <AddItemSection>
//                 <h3>Add Item:</h3>
//                 <Input
//                     type="text"
//                     placeholder="Product Name"
//                     value={newItem.name || ''}
//                     onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
//                 />
//                 <Input
//                     type="number"
//                     placeholder="Price"
//                     value={newItem.price || ''}
//                     onChange={(e) => setNewItem({ ...newItem, price: e.target.value })}
//                 />
//                 <CartButtonWrapper>
//                     <CartButton onClick={handleAddItem}>Add to Cart</CartButton>
//                 </CartButtonWrapper>
//             </AddItemSection>
            
//             <PaymentOptionsSection>
//                 <h3>Payment Options:</h3>
//                 <PaymentOptionLabel>
//                     <input
//                         type="radio"
//                         value="card"
//                         checked={paymentOption === 'card'}
//                         onChange={handlePaymentOptionChange}
//                     />
//                     Pay with Card
//                 </PaymentOptionLabel>
//                 <PaymentOptionLabel>
//                     <input
//                         type="radio"
//                         value="paypal"
//                         checked={paymentOption === 'paypal'}
//                         onChange={handlePaymentOptionChange}
//                     />
//                     Pay with PayPal
//                 </PaymentOptionLabel>
//                 <CartButtonWrapper>
//                     <CartButton primary={true} onClick={handlePayment}>
//                         {loading ? 'Processing...' : 'Proceed to Payment'}
//                     </CartButton>
//                 </CartButtonWrapper>
//             </PaymentOptionsSection>
            
//             {errorMessage && <p className="error-message">{errorMessage}</p>}
            
//             <p>Total Price: {calculateTotalPrice()} €</p>
//         </ShoppingCartContainer>
//     );
// };

// export default ShoppingCart;

// //     return (
//     const ShoppingCart = () => {
//         const { cartItems, setCartItems } = useCart();
//         const [newItem, setNewItem] = useState({});
//         const [paymentOption, setPaymentOption] = useState('');
    
//         const handleAddItem = () => {
//             setCartItems([...cartItems, newItem]);
//             setNewItem({});
//         };
    
//         const handlePaymentOptionChange = (e) => {
//             setPaymentOption(e.target.value);
//         };
    
//         const cartItemsCount = cartItems.length;
//         const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);
    
//         const removeFromCart = (itemId) => {
//             axios.delete(`${baseUrl}/api/cart/${itemId}`).then((response) => {
//                 setCartItems(response.data);
//             });
//         };
    
//         useEffect(() => {
//             // eslint-disable-next-line 
//             loadCartItemsFromLocalStorage();
//         }, []);
        
//         const updateLocalStorage = (newCartItems) => {
//             localStorage.setItem('cartItems', JSON.stringify(newCartItems));
//         };
    
//         const loadCartItemsFromLocalStorage = () => {
//             const storedCartItems = localStorage.getItem('cartItems');
//             if (storedCartItems) {
//                 setCartItems(JSON.parse(storedCartItems));
//             }
//         };
    
//         useEffect(() => {
//             updateLocalStorage(cartItems);
//         }, [cartItems]);
    
//         return (
//         <>
//             <TopBar cartItemsCount={cartItemsCount} />

//             <ShoppingCartContainer>
//                 <ShoppingCartHeading>Shopping Cart</ShoppingCartHeading>
//                 <CartContainer>
//                     {/* <CartTitle>Cart</CartTitle> */}
//                     {cartItems.map((item) => (
//                         <CartItem key={item.id}>
//                             <CartItemImage src={item.image} alt={item.name} />
//                             <CartItemInfo>
//                                 <CartItemName>{item.name}</CartItemName>
//                                 <CartItemPrice>{item.price} €</CartItemPrice>
//                                 <RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>
//                             </CartItemInfo>
//                         </CartItem>
//                     ))}
//                 </CartContainer>
//                 {/* {cartItems.length ? (
//                     cartItems.map((item) => (
//                         <Item key={item.id} item={item.product} onRemove={removeFromCart} />
//                     ))
//                 ) : (
//                     <div>No items in the cart</div>
//                 )} */}
//                 <AddItemSection>
//                     <h3>Add Item:</h3>
//                     <Input
//                         type="text"
//                         placeholder="Product Name"
//                         value={newItem.name || ''}
//                         onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
//                     />
//                     <Input
//                         type="number"
//                         placeholder="Price"
//                         value={newItem.price || ''}
//                         onChange={(e) => setNewItem({ ...newItem, price: e.target.value })}
//                     />
//                     <CartButtonWrapper>
//                         <CartButton onClick={handleAddItem}>Add to Cart</CartButton>
//                     </CartButtonWrapper>
//                 </AddItemSection>

//                 <PaymentOptionsSection>
//                     <h3>Payment Options:</h3>
//                     <PaymentOptionLabel>
//                         <input
//                             type="radio"
//                             value="card"
//                             checked={paymentOption === 'card'}
//                             onChange={handlePaymentOptionChange}
//                         />
//                         Pay with Card
//                     </PaymentOptionLabel>
//                     <PaymentOptionLabel>
//                         <input
//                             type="radio"
//                             value="paypal"
//                             checked={paymentOption === 'paypal'}
//                             onChange={handlePaymentOptionChange}
//                         />
//                         Pay with PayPal
//                     </PaymentOptionLabel>
//                     <CartButtonWrapper>
//                         <CartButton primary={true}>Proceed to Payment</CartButton>
//                     </CartButtonWrapper>
//                 </PaymentOptionsSection>

//                 {/* Display the total price */}
//                 <p>Total Price: {totalPrice} €</p>
//             </ShoppingCartContainer>
//         </>
//     );
// };

// export default ShoppingCart;
