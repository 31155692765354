// Create a new context for managing products data
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from './App';

const ProductsContext = createContext();
const token = localStorage.getItem('token');
const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
};
const ProductsProvider = ({ children }) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        // Fetch products data from the server
        axios.get(`${baseUrl}/api/products`, headers).then((response) => {
            setProducts(response.data);
        });
    }, []);

    return (
        <ProductsContext.Provider value={products}>
            {children}
        </ProductsContext.Provider>
    );
};

export { ProductsContext, ProductsProvider };
