import React, { useEffect, useRef } from 'react';

const WebGLNeonCursor = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const gl = canvas.getContext('webgl');
    if (!gl) {
      console.error("Unable to initialize WebGL.");
    }

    // Rest of your WebGL code here...

  }, []);

  return <canvas ref={canvasRef} />;
};

export default WebGLNeonCursor;
