import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { baseUrl } from './../App';

const ArticleListWrapper = styled.div`
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
`;

const ArticleItem = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 10px;
`;

const ArticleList = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    axios.get(`${baseUrl}/api/article`).then((response) => {
      setArticles(response.data);
    });
  }, []);

  return (
    <ArticleListWrapper>
      <h2>Articles</h2>
      {articles.map((article) => (
        <ArticleItem key={article.id}>
          <h3>{article.title}</h3>
          <p>{article.content}</p>
        </ArticleItem>
      ))}
    </ArticleListWrapper>
    
  );
};

export default ArticleList;
